import { useEffect, useState, useRef } from "react";
import "./Buscador.css";
import List from "../components/List";
import GestionBase from "../components/GestionBase";
import { ModalElement, showModal } from "../components/Modal";
import Tabla from "../components/Tabla";
import FiltroColumna from "../components/FiltroColumna";
import { oficioRegional_get } from "../services/Api/OficioRegional";
import { oficioCorporativo_get } from "../services/Api/OficioCorporativo";
import { toDateInputValue } from "../util/Fecha";
import { region_get } from "../services/Api/Region";

Date.prototype.toDateInputValue = toDateInputValue;
function fechaIntupt (){
    const _fecha = new Date();
    return _fecha.toDateInputValue();
}


export default function Buscador(){
    const errorModalId = "modal-region-error";
    const [errorBody, setErrorBody] = useState('');
    const [entities, setEntities] = useState(new Map());
    const [numeroOficio, setNumeroOficio] = useState('');
    const [buscando, setBuscando] = useState(false);
    const [fechaIni, setFechaIni] = useState(fechaIntupt());
    const [fechaFin, setFechaFin] = useState(fechaIntupt());
    const [regiones, setRegiones] = useState([]);
    const [regionSelected, setRegionSelected] = useState('');
    const [buscarLeyenda, setBuscarLeyenda] = useState('Buscar');

    const tabs = [
        {
            id: 'region',
            name:'Región',
            subtitle: 'Oficio regional',
            img: '/img/icono_oficio_servicio.png',
            pdfDownloadUrl: process.env.REACT_APP_BACKEND_URL + '/api/oficioregional/pdf/',
            excelDownloadUrl: process.env.REACT_APP_BACKEND_URL + '/api/oficioregional/excel/',
            tableHeads: [
                {
                    title: 'Oficio',
                    prop: 'id',
                    dineroFormat: false,
                },
                {
                    title: 'Fecha',
                    prop: 'fecha',
                    dineroFormat: false,
                },
                {
                    title: 'Precio',
                    prop: 'precio',
                    dineroFormat: true,
                },
                {
                    title: 'Subtotal',
                    prop: 'subtotal',
                    dineroFormat: true,
                },
                {
                    title: 'IVA',
                    prop: 'iva',
                    dineroFormat: true,
                },
                {
                    title: 'Total',
                    prop: 'total',
                    dineroFormat: true,
                },
                {
                    title: 'Situacion',
                    prop: 'situacion',
                    dineroFormat: false,
                },
                {
                    title: 'PDF',
                    prop: 'pdf',
                    dineroFormat: false,
                },
                {
                    title: 'Excel',
                    prop: 'excel',
                    dineroFormat: false,
                }
            ]
        },
        {
            id: 'corporativo',
            name:' Corporativo',
            subtitle: 'Oficio corporativo',
            img: "/img/icono_folio_corporativo.png",
            pdfDownloadUrl: process.env.REACT_APP_BACKEND_URL + '/api/oficiocorporativo/pdf/',
            excelDownloadUrl: process.env.REACT_APP_BACKEND_URL + '/api/oficiocorporativo/excel/',
                    tableHeads: [
                {
                    title: 'Oficio',
                    prop: 'id',
                    dineroFormat: false,
                },
                {
                    title: 'Fecha',
                    prop: 'fecha',
                    dineroFormat: false,
                },
                {
                    title: 'PDF',
                    prop: 'pdf',
                    dineroFormat: false,
                },
                {
                    title: 'Excel',
                    prop: 'excel',
                    dineroFormat: false,
                },
            ]
        }
    ];

    const [activeTab, setActiveTab] = useState(tabs[0]);

    const numeroOficioRef = useRef(null);
    const fechaIniRef = useRef(null);
    const fechaFinRef = useRef(null);

    const title = 'Buscador de archivos';

    useEffect(() => {
        region_get({
            all: true,
            page_size: 0,
            columns_order: 'descripcion',
            columns_sort: 'asc',
        }).then(result => {
            if (result.ok){
                setRegiones(result.data);
            }
            else{
                setErrorBody(result.data);
                showModal(errorModalId);
            }
        })
    }, []);



    const tabClass = (tab) => {
        if (tab['id'] === activeTab['id']){
            return 'active tab-active'
        }
        return ''
    }

    const handleBuscar = () => {
        setEntities(new Map());
        setBuscando(true);

        if (activeTab.id === 'region'){
            const query = {};
            if (numeroOficio !== ''){
                query.id = numeroOficio
            }else{
                query.fecha_ini = fechaIni;
                query.fecha_fin = fechaFin;
                query.region_ids = regionSelected
            }

            oficioRegional_get(query).then(result => {
                if (result.ok){
                    const oficios = new Map();
                    result.data.forEach(oficio => {
                        oficio.pdf = (
                            <form
                                action={activeTab.pdfDownloadUrl + oficio.id}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="submit"
                                    className="btn-pdf"
                                    title="Descargar pdf"
                                >
                                    <i className="bi bi-filetype-pdf"></i>
                                </button>
                            </form>
                        );

                        oficio.excel = (
                            <form
                                action={activeTab.excelDownloadUrl + oficio.id}
                                target="_blank"
                            >
                                <button
                                    type="submit"
                                    className="btn-excel"
                                    title="Descargar excel"
                                >
                                    <i className="bi bi-file-earmark-excel"></i>
                                </button>
                            </form>
                        );

                        oficios.set(oficio.id, oficio);
                    })

                    setEntities(oficios);
                }else{
                    setErrorBody(result.data);
                    showModal(errorModalId);
                }
                setBuscando(false);
            });
        }
        else{
            const query = {};
            if (numeroOficio !== ''){
                query.id = numeroOficio
            }else{
                query.fecha_ini = fechaIni;
                query.fecha_fin = fechaFin;
                query.region_id = regionSelected
            }

            oficioCorporativo_get(query).then(result => {
                if (result.ok){
                    const oficios = new Map();
                    result.data.forEach(oficio => {
                        oficio.pdf = (
                            <form
                                action={activeTab.pdfDownloadUrl + oficio.id}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="submit"
                                    className="btn-pdf"
                                    title="Descargar pdf"
                                >
                                    <i className="bi bi-filetype-pdf"></i>
                                </button>
                            </form>
                        );

                        oficio.excel = (
                            <form
                                action={activeTab.excelDownloadUrl + oficio.id}
                                target="_blank"
                            >
                                <button
                                    type="submit"
                                    className="btn-excel"
                                    title="Descargar excel"
                                >
                                    <i className="bi bi-file-earmark-excel"></i>
                                </button>
                            </form>
                        );

                        oficios.set(oficio.id, oficio);
                    })

                    setEntities(oficios);
                }else{
                    setErrorBody(result.data);
                    showModal(errorModalId);
                }
                setBuscando(false);
            });
        }
    }

    const handleLimpiar = () => {
        setEntities(new Map());
        setNumeroOficio('');
        setBuscarLeyenda('Buscar');
    }

    const handleTabClick = (tab) => {
        if (tab.id !== activeTab.id){
            setEntities(new Map());
            setNumeroOficio('');
            setBuscarLeyenda('Buscar');
            setActiveTab(tab);
        }
    }

    const handleChangeFolio = () => {
        setNumeroOficio(numeroOficioRef.current.value);

        if (numeroOficioRef.current.value !== ''){
            setBuscarLeyenda('Buscar No. de oficio');
        }else{
            setBuscarLeyenda('Buscar');
        }
    }

    const handleChangeFechaIni = () => {
        setFechaIni(fechaIniRef.current.value)
    }

    const handleChangeFechaFin = () => {
        setFechaFin(fechaFinRef.current.value)
    }

    let renderTabs = () => {
        const _tabs = [];

        tabs.forEach((tab, i) => {
            _tabs.push(
                <li
                    key={'tab_' + tab['name']}
                    className="nav-item"
                    type="button"
                    onClick={() => handleTabClick(tab)}

                >
                    <a
                        id={"tab-" + i}
                        className={"nav-link " + tabClass(tab)}
                    >
                        {tab['name']}
                    </a>
            </li>
            )
        })

        return (
            <ul className="nav nav-tabs">
                {_tabs}
            </ul>
        )
    }

    const renderFilter = () => {
        return (
        <div>
            <div>
                {renderTabs()}
            </div>
            <div id="filter" className="row pb-1">
                <FiltroColumna title={"Fecha inicial"} flexLgCol={4}>
                    <input
                        ref={fechaIniRef}
                        type="date"
                        className="ps-1 pe-1 full-width dropdown-selector"
                        defaultValue={fechaIni}
                        onChange={handleChangeFechaIni}
                    ></input>
                </FiltroColumna>
                <FiltroColumna title={"Fecha final"} flexLgCol={4}>
                    <input
                        ref={fechaFinRef}
                        type="date"
                        className="ps-1 pe-1 full-width dropdown-selector"
                        defaultValue={fechaFin}
                        onChange={handleChangeFechaFin}
                    ></input>
                </FiltroColumna>
                <List name={"Región"} entities={regiones} setSelected={setRegionSelected} flexLgCol={4} flexSubLgCol1={3} flexSubLgCol2={9}/>
                <FiltroColumna title={"Buscar " + activeTab['subtitle']} flexLgCol={4}>
                    <div className="row g-0 justify-content-around">
                        <div className="col ">
                            <div className=" text-center numero-oficio">
                                <input
                                    id="filtro-folio-input"
                                    className="w-100"
                                    ref={numeroOficioRef}
                                    onChange={handleChangeFolio}
                                    value={numeroOficio}
                                >
                                </input>
                            </div>
                        </div>
                    </div>
                </FiltroColumna>
                <div className="col-md-2 ps-1">
                    <button
                        id="buscar-oficio"
                        className="btn-filter"
                        onClick={handleBuscar}
                    >
                    <i className="bi bi-search"></i> {buscarLeyenda}
                    </button>
                </div>
                <div className="col-md-2 ps-1 pe-1">
                    <button
                        id="limpiar-filtro"
                        className="btn-filter"
                        onClick={handleLimpiar}
                    >
                        <img src="/img/icono_limpiar.png" height={"16px"}/> Limpiar
                    </button>
                </div>
            </div>
        </div>
        )
    }

    return(
        <GestionBase
            title= {title}
            subtitle= {activeTab['subtitle']}
            image= {activeTab['img']}
            filtro= {renderFilter()}
            table = {<Tabla
                heads= {activeTab['tableHeads']}
                entities= {entities}
                entityName= {activeTab['name']}
                entityIdName= {'id'}
                buscando= {buscando}
            />}
        >
            <ModalElement modalId= {errorModalId} body={errorBody} showCancelar={false}/>
        </GestionBase>
    );
}